"use client";

import { FC, useState } from "react";
import Image from "next/image";
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "@/components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { Checkbox } from "@/components/ui/checkbox";
import { login } from "@/actions/login";
import { useRouter } from "next/navigation";
import useUserStore from "@/lib/stores/user.store";
import { useToast } from "@/hooks/useToast";

export const LoginSchema = z.object({
  email: z.string()
    .min(1, { message: "El correo electrónico es obligatorio" })
    .email({
      message: "Por favor, ingresa un correo electrónico válido",
    }),
  password: z.string()
    .min(1, { message: "La contraseña es obligatoria" })
})

const LoginClient: FC = () => {
  const router = useRouter();
  const { setUser, setRole, setMenus, setToken } = useUserStore();
  const [isLogin, setIsLogin] = useState(false);
  const { toast } = useToast()
  const form = useForm<z.infer<typeof LoginSchema>>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  })

  const onSubmit = async (data: z.infer<typeof LoginSchema>) => {
    setIsLogin(true);
    try {
      await login(data).then((data) => {
        if (data.error) {
          toast({
            variant: "destructive",
            title: "Opps! Algo salió mal",
            description: data.error,
            duration: 5000,
          })

          setIsLogin(false);
        }

        if (data.success) {
          setUser(data.user);
          setRole(data.role);
          setMenus(data.menus);
          setToken(data.token);
          router.push("/");
        }
      });
    } catch (err) {
      console.log(`Something went wrong! Error:${err}`);
    }
  }

  return (
    <div className="container max-w-7xl min-h-screen flex content-center items-center justify-center">
      <div className="flex items-center justify-items-center max-h-[585px] gap-4 font-[family-name:var(--font-geist-sans)] w-full">
        <div className="flex flex-col gap-6 items-center w-[672px]">
          <h1 className="font-extrabold text-6xl leading-[60px]">Bienvenido a la plataforma</h1>
          <p className="font-normal text-[20px] leading-8 text-gray-400">Ingresa a la plataforma para gestionar eficientemente los recursos de tu empresa.</p>
          <Image
            className="dark:invert"
            src="/images/login-group.svg"
            alt="Next.js logo"
            width={700}
            height={500}
            priority
          />
        </div>
        <div className="flex flex-1 justify-end">
          <div className="bg-gray-800 rounded-lg w-full max-w-[448px] min-h-80 p-8">
            <h1 className="font-bold text-2xl leading-9">Iniciar sesión</h1>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>E-mail</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="email"
                          placeholder=""
                          className={fieldState.invalid ? "border-red-500" : ""}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field, fieldState }) => (
                    <FormItem>
                      <FormLabel>Contraseña</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          type="password"
                          placeholder=""
                          className={fieldState.invalid ? "border-red-500" : ""}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="flex items-center space-x-2">
                  <Checkbox id="terms" />
                  <label
                    htmlFor="terms"
                    className="text-sm font-medium leading-4"
                  >
                    Recordarme
                  </label>
                </div>

                <Button 
                  type="submit" 
                  className="w-full bg-blue-700"
                  disabled={isLogin}
                >
                  Iniciar sesión
                </Button>

                <Button variant="link" className=" text-blue-500 px-0">¿Olvidaste tu contraseña?</Button>
              </form>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginClient;