import { create } from 'zustand';
import { persist, StateStorage, PersistOptions } from 'zustand/middleware';
import { User, UserRole, UserMenu } from '../domains/user.interface';


type Store = {
  user: User;
  role: UserRole;
  menus: UserMenu[];
  token: string;
  setUser: (user: User) => void;
  setRole: (role: UserRole) => void;
  setMenus: (menus: UserMenu[]) => void;
  setToken: (token: string) => void;
}

const storeConfig = (set: (partial: Partial<Store>) => void): Store => ({
  user: {
    id: '',
    name: '',
    lastname: '',
    email: '',
    isActive: false,
  },
  role: {
    id: '',
    name: '',
    description: '',
  },
  menus: [],
  token: '',
  setUser: (user: User) => set({ user }),
  setRole: (role: UserRole) => set({ role }),
  setMenus: (menus: UserMenu[]) => set({ menus }),
  setToken: (token: string) => set({ token }),
});

const useUserStore = create<Store>()(
  persist<Store, [], []>(
    storeConfig,
    {
      name: 'app-storage',
      getStorage: (): StateStorage => localStorage,
      serialize: (state: { state: Store; version: number }) => {
        return JSON.stringify({
          state: state.state,
          version: state.version,
          timestamp: Date.now(),
        });
      },
      deserialize: (str: string) => {
        const data = JSON.parse(str);
        const currentTime = Date.now();
        const expireTime = 30 * 24 * 60 * 60 * 1000; // 30 días en milisegundos

        if (currentTime - data.timestamp > expireTime) {
          return { state: undefined, version: data.version };
        }

        return { state: data.state, version: data.version };
      },
    } as PersistOptions<Store>
  )
);

export default useUserStore;